import { Text, View } from '@cnd/elements'

const RecheckingModal = ({ text }) => (
  <>
    <View
      style={{
        zIndex: 99,
        padding: 10,
        borderRadius: 10,
        background: `rgba(0,0,0,0.18)`,
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    />
    <View
      center
      style={{
        zIndex: 100,
        padding: 10,
        borderRadius: 10,
        background: `rgba(255,255,255,0.95)`,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Text weight={500}>{text}</Text>
    </View>
  </>
)

export default RecheckingModal
