import { Button, Card, GreenButton, Spacer, Text, View } from '@cnd/elements'
import { useTypedDispatch, useTypedSelector } from '@store'

import { useEffect } from 'react'
import { load } from 'redux-localstorage-simple'
import initialState, { InitialState } from './initialState'
import { fixFuckUp, resetAllPending } from './initialConfiguration'
import { mergeDeepRight } from 'ramda'
import { absoluteFillObjectZ } from '@services/StyleSheet'
import { Capacitor } from '@capacitor/core'
import { CapacitorUpdater } from '@capgo/capacitor-updater'

const Persist = ({ children, loading }) => {
  const dispatch = useTypedDispatch()
  const userIsLoaded = useTypedSelector((state) => state.user.loaded)
  const userIsLoading = useTypedSelector((state) => state.user.loading)
  const userHasFailedToLoad = useTypedSelector((state) => state.user.loadingError)
  const loadingFailed = useTypedSelector((state) => state.user.loadingFailed)

  useEffect(() => {
    const rootState = load({ namespace: 'root' }) as InitialState

    const fixedState = {
      user: mergeDeepRight(initialState.user, fixFuckUp(rootState.user)),
      ...mergeDeepRight(rootState, {
        sidebar: initialState.sidebar,
        onLoginReturnTo: initialState.onLoginReturnTo,
        sidebarBottom: initialState.sidebarBottom,
      }),
    }

    dispatch({ type: 'SET_PERSISTED_STATE', payload: resetAllPending(fixedState) })
  }, [])

  const reload = () => {
    if (Capacitor.isNativePlatform()) {
      localStorage.clear()
      CapacitorUpdater.reload()
    } else {
      localStorage.clear()
      window.location.reload()
    }
  }

  if (loadingFailed)
    return (
      <View center style={{ ...absoluteFillObjectZ(100), backgroundColor: 'black' }}>
        <Text color="white" weight={400} style={{ maxWidth: 300, lineHeight: 1.4 }} center size={16}>
          There was a problem loading your data. Please try again.
        </Text>
        <Spacer height={30} />
        <Button style={{ alignSelf: 'auto' }} color="white" onClick={reload}>
          <Text weight={600} style={{ maxWidth: 300 }}>
            Reload
          </Text>
        </Button>
      </View>
    )

  if (userIsLoading) return loading
  if (userHasFailedToLoad)
    return (
      <View>
        <Spacer height={30} />
        <Card>Hiro struggled to start</Card>
        <Spacer height={20} />
        <GreenButton onClick={reload}>Try again</GreenButton>
      </View>
    )
  if (!userIsLoaded) return loading
  return children
}

export default Persist
