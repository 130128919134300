type PreorderDeliveryTimeSlot = {
  hour: number
  minutes: number
  timeName: string
}

const hourIn12 = (hour: number) => (hour < 13 ? hour : hour - 12)

const generatePreorderDeliveryTimeSlots = () => {
  let timeSlots: PreorderDeliveryTimeSlot[] = []
  let brackets = [
    [0, 20],
    [20, 40],
    [40, 60],
  ]
  for (let t = 0; t < 24; t++) {
    for (let m = 0; m < brackets.length; m++) {
      const bracket = brackets[m]

      const am = t < 12 ? 'am' : 'pm'
      const sunOrMoon = t >= 5 && t <= 19 ? '☀️' : '🌙'

      let hourIn12From = hourIn12(t)
      let hourIn12To = bracket[1] === 60 ? hourIn12(hourIn12From + 1) : hourIn12From

      let minsFrom = bracket[0] === 0 ? '00' : bracket[0]
      let minsTo = bracket[1] === 60 ? '00' : bracket[1]

      let isMidday = hourIn12To === 12 && bracket[1] === 60 ? true : false

      let timeName = `${sunOrMoon} ${hourIn12From}:${minsFrom} - ${hourIn12To}:${minsTo} ${
        isMidday ? 'midday' : am
      }`

      timeSlots.push({ timeName, hour: t, minutes: bracket[0] })
    }
  }

  return timeSlots
}

const preorderDeliveryTimeSlots = generatePreorderDeliveryTimeSlots()

export type PreorderDeliveryTimeSlots = PreorderDeliveryTimeSlot[]

export default preorderDeliveryTimeSlots
