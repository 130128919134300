import { useEffect, useState } from 'react'

import { usePrevious } from '@uidotdev/usehooks'
import RecheckingModal from './RecheckingModal'
import ThereWasAProblemUpdating from './ThereWasAProblemUpdating'
import { useTypedSelector } from '@store'
import { isOnPageWhereUpdateRuns, shouldTryToLoadNewWebUpdate } from '@services/version'
import { values } from 'ramda'

const WebUpdateOnStateChange = () => {
  const currentPage = useTypedSelector((state) => state.route.current)
  const sidebarData = useTypedSelector((state) => state.sidebarBottom.data)
  const [is, setIs] = useState({ rechecking: false, error: false })
  const was = usePrevious(is)

  const restartWebsite = () => {
    window.location.reload()
  }

  const updateIs = (newIs) => setIs({ ...is, ...newIs })

  const onBrowserVisibleChangeRun = () => {
    if (document.hidden && isOnPageWhereUpdateRuns(currentPage) && values(sidebarData).length === 0) {
      console.log('Browser tab is visible')
      updateIs({ rechecking: true, error: false })
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', onBrowserVisibleChangeRun)
    return () => {
      removeEventListener('visibilitychange', onBrowserVisibleChangeRun)
    }
  }, [])

  useEffect(() => {
    if (!was?.rechecking && is.rechecking) {
      shouldTryToLoadNewWebUpdate()
        .then((update) => {
          console.log('shouldWebsiteUpdate', update)
          if (update) return restartWebsite()
          updateIs({ error: false, rechecking: false })
        })
        .catch(() => {
          console.log('error checking version')
          updateIs({ error: true, recheckng: false })
        })
    }
  }, [is, was])

  if (is.error) return <ThereWasAProblemUpdating tryAgainFcn={restartWebsite} />
  if (is.rechecking) return <RecheckingModal text="Checking for updates..." />

  return null
}

export default WebUpdateOnStateChange
