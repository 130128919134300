import { getIsBetweenStoreOpeningHours, getHasLaunched } from '@services/shopHelpers'

import { format, getDate, add, addDays, formatISO, set, getHours } from 'date-fns'
import { clone, filter, find, map, pipe, prop, propEq, sortBy, values } from 'ramda'

import { useTypedSelector } from '@store'
import { BusinessHoursState } from './initialState'
import preorderDeliveryTimeSlots from './preorderDeliveryTimeSlots'

export type PreorderDeliveryDay = {
  isoDate: string
  dayName: string
  daysInTheFuture: number
  timeSlots?: { hour: number; minutes: number; timeName: string }[]
}

function getPreorderDeliveryDays(): PreorderDeliveryDay[] {
  let todayAtMidnight = set(new Date(), { seconds: 0, minutes: 0, hours: 0, milliseconds: 0 })

  const days = []

  for (let i = 0; i < 7; i++) {
    const nextDay = addDays(todayAtMidnight, i)
    days.push({ isoDate: formatISO(nextDay), dayName: format(nextDay, 'EEEE'), daysInTheFuture: i })
  }

  return days
}

export const doesBufferTimeTakeUsPastMidnight = ({ bufferHours }) => {
  const dateTime = new Date()
  const dateTimeWithBuffer = add(dateTime, { hours: bufferHours })

  const date = getDate(dateTime)
  const dateWithBuffer = getDate(dateTimeWithBuffer)

  if (date !== dateWithBuffer) return true
  return false
}

const checkIfShopIsOpen = (openingHours, launch, online) => {
  let dayOfTheWeek = format(new Date(), 'EEEE')
  const { openclose: openingTimeRange } = find<any>(propEq(dayOfTheWeek, 'id'), openingHours)
  const openingHoursSplit = openingTimeRange.split('-')
  const open = openingHoursSplit[0]
  const close = openingHoursSplit[1]

  const isBetweenStoreOpeningHours = getIsBetweenStoreOpeningHours({
    open,
    close,
  })

  const devMode = document.cookie.includes('devMode=true')

  let wereOffline = false
  if (isBetweenStoreOpeningHours === false || getHasLaunched(launch) === false || !online) {
    wereOffline = true
  }

  let isShopOpen = !wereOffline
  if (devMode) isShopOpen = devMode

  return { isShopOpen, open, close }
}

const isTimeSlotAvailable =
  (open: string, close: string) =>
  ({ hour, minutes }) => {
    if (open === 'closed') return false
    const openHour = parseInt(open.split(':')[0])
    const openMins = parseInt(open.split(':')[1])
    const closeHour = parseInt(close.split(':')[0])
    const closeMins = parseInt(close.split(':')[1])
    if (hour >= openHour && hour <= closeHour) {
      if (hour === openHour && minutes === openMins) return false
      if (hour === closeHour && minutes > closeMins) return false
      return true
    }
    return false
  }

const isTimeSlotAfter =
  ({ time, buffer }) =>
  ({ hour }) => {
    const hours = getHours(new Date(time))
    if (hours + buffer < hour) return true
    return false
  }

const filterAvailableTimeSlots = ({ open, close, buffer, time }) =>
  pipe(filter(isTimeSlotAvailable(open, close)), filter(isTimeSlotAfter({ time, buffer })))

export const getDerivedBusinessHours = (location) => {
  let mutableLocation = clone(location)
  let {
    launch,
    offlineMessageSubtitle,
    offlineMessageTitle,
    openingHours,
    soldOut,
    online,
    deliveryAvailable,
    technicalProblems,
    hoursAferNowCanPreorder,
  } = mutableLocation

  soldOut = Boolean(soldOut)
  technicalProblems = Boolean(technicalProblems)
  online = Boolean(online)
  deliveryAvailable = Boolean(deliveryAvailable)
  launch = parseInt(launch)
  offlineMessageSubtitle = String(offlineMessageSubtitle)
  offlineMessageTitle = String(offlineMessageTitle)
  openingHours = sortBy(prop('order'), values(openingHours))

  let { isShopOpen, open, close } = checkIfShopIsOpen(openingHours, launch, online)

  const orderWouldArriveAfterMidnight = doesBufferTimeTakeUsPastMidnight({
    bufferHours: hoursAferNowCanPreorder,
  })

  // just today
  let availablePreorderDeliveryTimeSlotsToday = filterAvailableTimeSlots({
    open,
    close,
    buffer: hoursAferNowCanPreorder,
    time: new Date(),
  })(preorderDeliveryTimeSlots)

  let availablePreorderDeliveryDays = filter(({ daysInTheFuture }) => {
    if (orderWouldArriveAfterMidnight || availablePreorderDeliveryTimeSlotsToday.length === 0) {
      let isToday = daysInTheFuture === 0
      if (isToday) return false
    }
    return true
  }, getPreorderDeliveryDays())

  availablePreorderDeliveryDays = map(({ isoDate, dayName, daysInTheFuture }) => {
    let [open, close] = find<any>(propEq(dayName, 'id'), openingHours).openclose.split('-')
    if (open === 'closed') close = '00:00'

    const isToday = daysInTheFuture === 0

    return {
      isoDate,
      dayName,
      daysInTheFuture,
      timeSlots: filterAvailableTimeSlots({
        open,
        close,
        buffer: hoursAferNowCanPreorder,
        time: isToday ? new Date() : new Date(isoDate),
      })(preorderDeliveryTimeSlots),
    }
  }, availablePreorderDeliveryDays)

  const newDerivedBusinessHours = {
    ...location,
    open,
    close,
    soldOut,
    launch,
    offlineMessageSubtitle,
    offlineMessageTitle,
    isShopOpen,
    deliveryOnline: isShopOpen && deliveryAvailable,
    isShopClosed: !isShopOpen,
    technicalProblems,
    openingHours,
    hoursAferNowCanPreorder,
    preorderDeliveryDays: availablePreorderDeliveryDays,
  }

  return newDerivedBusinessHours
}

const useBusinessHours = ():
  | BusinessHoursState
  | { isShopOpen: null; isShopClosed: null; openingHours: any; open: null; close: null } => {
  const closestToUser = useTypedSelector((s) => s.locations?.closestToUser)

  return !!closestToUser
    ? closestToUser
    : { isShopClosed: null, isShopOpen: null, openingHours: [], open: null, close: null }
}

export default useBusinessHours
