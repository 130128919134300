import { Splash } from '@components/Splash'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const LanguagePicker = ({ children }) => {
  const { i18n, ready } = useTranslation()

  useEffect(() => {
    const lang = navigator.language
    console.log('User is on: ', lang)
    i18n.changeLanguage(lang)
  }, [])

  if (!ready) return <Splash />
  return children
}

export default LanguagePicker
