import styleSheet from '@services/StyleSheet'
import Image from 'next/image'
import { View } from '@cnd/elements'
import colors from '@constants/colors'

export const Splash = ({ whatsLoading = null }) => {
  return (
    <View
      center
      style={{
        flex: 1,
        ...styleSheet.absoluteFillObject,
        backgroundColor: colors.nWhite,
        backgroundImage: `url(/my_hiro_splash.webp)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      {!whatsLoading && (
        <Image src="/my_hiro.webp" alt="My Hiro Logo" priority width={927.47 * 0.2} height={674 * 0.2} />
      )}
    </View>
  )
}
