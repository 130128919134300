import { useEffect, useState } from 'react'
import { Splash } from '../Splash'

import { usePrevious } from '@uidotdev/usehooks'
import { isWebsite } from '@services/platform'
import ThereWasAProblemUpdating from './ThereWasAProblemUpdating'
import { shouldTryToLoadNewWebUpdate } from '@services/version'

const initialIs = { checking: false, error: false }

const WebUpdateOnLaunch = ({ children }) => {
  const [isChecking, setIsChecking] = useState(false)
  const [isError, setIsError] = useState(initialIs.error)

  const wasChecking = usePrevious(isChecking)

  const restartWebsite = () => window.location.reload()

  const updateIs = (newIs) => {
    if (typeof newIs.checking === 'boolean') setIsChecking(newIs.checking)
    if (newIs.error === true) setIsError(newIs.error)
  }

  useEffect(() => {
    if (!wasChecking && isChecking) {
      shouldTryToLoadNewWebUpdate()
        .then((update) => {
          if (update) return restartWebsite()
          updateIs({ error: false, checking: false })
        })
        .catch(() => {
          console.log('error checking version')
          updateIs({ error: true, checkng: false })
        })
    }
  }, [isChecking, wasChecking])

  useEffect(() => {
    if (isWebsite) updateIs({ checking: true })
  }, [])

  if (isError) return <ThereWasAProblemUpdating tryAgainFcn={restartWebsite} />
  if (isChecking) return <Splash />
  return children
}

export default WebUpdateOnLaunch
