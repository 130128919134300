import { useTypedSelector } from '@store'
import { STRIPE_ENV } from '@constants/stripe'
import { UserState } from './initialState'

const useUser = <K extends keyof UserState>(userProp: K): UserState[K] =>
  useTypedSelector((state) => state.user[userProp])

export const pickStripeCustomerId = (user) => {
  let stripeCustomerId = null
  if (STRIPE_ENV === 'live') stripeCustomerId = user.stripeLiveCustomerId
  if (STRIPE_ENV === 'test') stripeCustomerId = user.stripeDevCustomerId
  return stripeCustomerId
}

export const useStripeCustomerId = () => {
  const stripeLiveCustomerId = useUser('stripeLiveCustomerId')
  const stripeDevCustomerId = useUser('stripeDevCustomerId')

  if (STRIPE_ENV === 'live') return stripeLiveCustomerId
  if (STRIPE_ENV === 'test') return stripeDevCustomerId
}

export default useUser
