const colors = {
  //yellow: "#fce379",
  yellow: '#ffcb00',
  red: '#E33326',
  logoYellow: '#F8E197',
  logoBackgrounRed: '#7E1900',
  lightGrey: 'rgba(0,0,0,0.04)',
  nBlack: (opacity = 1) => `rgba(51,20,28,${opacity})`,
  nWhite: '#FFFFF3',
  nnWhite: `#ffefcb`,
}

export default colors
