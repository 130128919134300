import { useFunDispatch } from '@store'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const useRoute = () => {
  const router = useRouter()
  const dispatch = useFunDispatch()

  useEffect(() => {
    const handleRouteChangeStart = (url) => {
      dispatch('SET_PREVIOUS_ROUTE', url)
    }

    const handleRouteChangeComplete = (url) => {
      dispatch('SET_CURRENT_ROUTE', url)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router])

  return null
}

export default useRoute
