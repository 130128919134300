import { CSSProperties } from 'react'

interface StyleSheetProps {
  absoluteFillObject: CSSProperties
  absoluteFillTop: CSSProperties
  absoluteFillBottom: CSSProperties
  fixedFillObject: CSSProperties
  fixedFillBottom: CSSProperties
  fixedFillTop: CSSProperties
}

export const absoluteFillObject = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
} as const

export const absoluteFillBottom = {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
} as const

export const absoluteFillTop = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
} as const

export const fixedFillObject = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
} as const

export const fixedFillBottom = {
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
} as const

export const fixedFillTop = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
} as const

export const absoluteFillTopZ = (zIndex: number) => ({
  ...absoluteFillTop,
  zIndex,
})

export const absoluteFillBottomZ = (zIndex: number) => ({
  ...absoluteFillBottom,
  zIndex,
})

export const absoluteFillObjectZ = (zIndex: number) => ({
  ...absoluteFillObject,
  zIndex,
})

const styleSheet: StyleSheetProps = {
  absoluteFillObject,
  absoluteFillTop,
  absoluteFillBottom,
  fixedFillObject,
  fixedFillBottom,
  fixedFillTop,
}

export default styleSheet
