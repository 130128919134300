import { createError } from '@cnd/redash'
import axios from 'axios'

export const API_BASE_URL = process.env.API_BASE_URL

const api = axios.create({
  baseURL: process.env.API_BASE_URL,
  timeout: 15000,
})

api.interceptors.response.use(
  function (config) {
    return config
  },
  function (error) {
    if (
      error &&
      error.message &&
      error.message.indexOf(`timeout`) > -1 &&
      error.message.indexOf(`exceeded`) > -1
    ) {
      if (!error.response) error.response = {}
      error.response.data = createError(`It looks like you have no or slow internet.`)
    }
    return Promise.reject(error)
  }
)

export default api
