import useInsights from '@hooks/useInsights'
import Script from 'next/script'

const Track = () => {
  const { enabled } = useInsights()

  if (enabled)
    return (
      <>
        <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.FIREBASE_measurementId}`} />
        <Script id="google-analytics">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.FIREBASE_measurementId}');
        `}
        </Script>
      </>
    )

  return null
}

export default Track
