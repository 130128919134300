import { useEffect, useState } from 'react'
import { dbRef } from '@services/firebase'
import { onValue, off } from 'firebase/database'
import * as Sentry from '@sentry/nextjs'

const useFirebaseConnect = (path, deps) => {
  const [value, setValue] = useState()

  useEffect(() => {
    onValue(dbRef(path), (snapshot) => {
      const unwrappedValue = snapshot.val()
      setValue(unwrappedValue)
    })

    return () => {
      console.log('Running useFirebaseConnect, off for path: ', path)
      off(dbRef(path))
    }
  }, deps)

  return value
}

let businessHoursListenerIsListening = false
const executeFirebaseBusinessHoursListener = (store) => {
  if (!businessHoursListenerIsListening) {
    businessHoursListenerIsListening = true
    onValue(
      dbRef(`businessHours`),
      (snapshot) => {
        const unwrappedValue = snapshot.val()
        console.log('Running firebaseListenerMiddleware, unwrappedValue')
        store.dispatch({
          type: 'SET_BUSINESS_HOURS',
          payload: unwrappedValue,
        })
      },
      (error) => {
        businessHoursListenerIsListening = false
        console.log('Running firebaseListenerMiddleware, off for path: ', 'businessHours')
        Sentry.captureMessage('Running firebaseListenerMiddleware, off for path: businessHours')
        Sentry.captureException(error)
      }
    )
  }
}

let menuListenerIsListening = false
const executeFirebaseMenuListener = (store) => {
  if (!menuListenerIsListening) {
    menuListenerIsListening = true
    onValue(
      dbRef(`menu`),
      (snapshot) => {
        const unwrappedValue = snapshot.val()
        console.log('Running firebaseListenerMiddleware, unwrappedValue')
        store.dispatch({
          type: 'SET_MENU',
          payload: { menu: unwrappedValue, membership: 'hiro' },
        })
      },
      (error) => {
        menuListenerIsListening = false
        console.log('Running firebaseListenerMiddleware, off for path: ', 'menu')
        Sentry.captureMessage('Running firebaseListenerMiddleware, off for path: menu')
        Sentry.captureException(error)
      }
    )
  }
}

const createListenter = (path, actionType, actionPayload) => (store) => {
  let isListening = false
  const executeFirebaseListener = (store) => {
    if (!isListening) {
      isListening = true
      onValue(
        dbRef(path),
        (snapshot) => {
          const unwrappedValue = snapshot.val()
          console.log(`Running firebaseListenerMiddleware, unwrappedValue on ${path}`)
          let payload = { [path]: unwrappedValue, ...actionPayload }
          store.dispatch({ type: actionType, payload })
        },
        (error) => {
          isListening = false
          console.log('Running firebaseListenerMiddleware, off for path: ', path)
          Sentry.captureMessage('Running firebaseListenerMiddleware, off for path: ' + path)
          Sentry.captureException(error)
        }
      )
    }
  }

  return executeFirebaseListener(store)
}

//let menuListener = createListenter('menu', 'SET_MENU', { membership: 'hiro' })

export const firebaseListenerMiddleware = (listeners) => (store) => (next) => (action) => {
  executeFirebaseBusinessHoursListener(store)
  executeFirebaseMenuListener(store)
  return next(action)
}

export default useFirebaseConnect
