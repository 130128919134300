import { CapacitorConfig } from '@capacitor/cli'
import packageJSON from './package.json'

const major_version_number = packageJSON.version.split('.')[0]

const config: CapacitorConfig = {
  appId: 'myhiro.club',
  appName: 'Hiro',
  webDir: 'out',
  backgroundColor: '#000000',
  server: {
    url: 'https://stuart.eu.ngrok.io',
  },
  plugins: {
    CapacitorUpdater: {
      autoUpdate: false,
      version: packageJSON.version,
      resetWhenUpdate: false,
      updateUrl: `https://api.myhiro.club/api/versions/${major_version_number}/latest?app_id=myhiro.club`,
      statsUrl: '',
    },
    SplashScreen: {
      launchAutoHide: false,
    },
  },
}

export default config
