import PackageJson from '../package.json'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { safeTry } from '@cnd/redash'
import { safePipe } from '@cnd/redash/dist/safeTry/safeTry'
import * as Sentry from '@sentry/nextjs'
import { split } from 'ramda'
import api from '@services/api'
import versionJson from '../version.json'

export const VERSION = PackageJson.version
export const EARLIEST_SUPPORTED_VERSION = PackageJson.earliestSupportedVersion

const pagesToUpdateOn = ['/Menu', '/', '/MenuItems', '/MyOrders', '', null]

export const isOnPageWhereUpdateRuns = (page) => {
  if (pagesToUpdateOn.indexOf(page) > -1) return true
  return false
}

const WAIT_TIME_FOR_API_RESPONSE_MS = 550

function newAbortSignal(timeoutMs) {
  const abortController = new AbortController()
  setTimeout(() => abortController.abort(), timeoutMs || 0)
  return abortController.signal
}

export const checkIfIsOnlineAndApiIsResponsive = async () => {
  try {
    const { data: result } = await api.get('/ping', { signal: newAbortSignal(WAIT_TIME_FOR_API_RESPONSE_MS) })
    if (result) return true
    return false
  } catch (error) {
    return false
  }
}

export const isActiveLessThanLatest = ({ activeVersion, latestVersion }) => {
  const activeVersionSplit = split('.', activeVersion)
  const latestVersionSplit = split('.', latestVersion)

  const activeVersionMajor = parseInt(activeVersionSplit[0])
  const activeVersionMinor = parseInt(activeVersionSplit[1])
  const activeVersionPatch = parseInt(activeVersionSplit[2])

  const latestVersionMajor = parseInt(latestVersionSplit[0])
  const latestVersionMinor = parseInt(latestVersionSplit[1])
  const latestVersionPatch = parseInt(latestVersionSplit[2])

  if (activeVersionMajor < latestVersionMajor) return true
  if (activeVersionMajor > latestVersionMajor) return false

  if (activeVersionMinor < latestVersionMinor) return true
  if (activeVersionMinor > latestVersionMinor) return false

  if (activeVersionPatch < latestVersionPatch) return true
  if (activeVersionPatch > latestVersionPatch) return false

  return false
}

export const getMajorVersion = (version) => parseInt(split('.', version)[0])

const captureException = (name) => safeTry(Sentry.captureException)({ name })

export const safeIsActiveLessThanLatest = safeTry(isActiveLessThanLatest)

export const safeGetAbsoluteLatestVersion = safeTry(
  async () => {
    const latestVersionResult = await fetch(`${process.env.API_BASE_URL}/versions/latest?app_id=myhiro.club`)

    const latestVersion = (await latestVersionResult.json()) as {
      url: string
      version: string
    }

    return latestVersion
  },
  {
    failed: true,
    version: VERSION,
    url: '',
  } as const
)

export const safeGetLatestVersion = safeTry(
  async () => {
    const latestVersionResult = await fetch(
      `${process.env.API_BASE_URL}/versions/${getMajorVersion(VERSION)}/latest?app_id=myhiro.club`
    )

    Sentry.setContext(`version_data`, { VERSION, API_BASE_URL: process.env.API_BASE_URL })

    const latestVersion = (await latestVersionResult.json()) as {
      url: string
      version: string
    }

    return latestVersion
  },
  {
    failed: true,
    version: VERSION,
    url: '',
  } as const
)

export const downloadLatestAppVersion = safeTry(
  CapacitorUpdater.download,
  captureException(`capgoDownloadFailed`)
)

export const setAppToLatestVersion = safeTry(CapacitorUpdater.set, captureException(`setAppToLatestVersion`))

export const getRunningVersion = () => versionJson.runningVersion

export const shouldTryToLoadNewWebUpdate = async () => {
  const isOnlineAndApiIsResponsive = await checkIfIsOnlineAndApiIsResponsive()
  if (!isOnlineAndApiIsResponsive) return false

  const {
    failed,
    result: { version },
  } = await safeGetAbsoluteLatestVersion()

  if (failed) return false

  const installedVersion = getRunningVersion()

  const { result: updating, success } = safeIsActiveLessThanLatest({
    activeVersion: installedVersion,
    latestVersion: version,
  })

  console.warn(
    `Update ${
      updating
        ? `found ${installedVersion} -> to -> ${version}`
        : `not found, on latest version: ${installedVersion}`
    }`
  )

  if (success) return updating
  return false
}

export const shouldTryToLoadNewUpdate = async () => {
  const isOnlineAndApiIsResponsive = await checkIfIsOnlineAndApiIsResponsive()
  if (!isOnlineAndApiIsResponsive) return false

  const {
    failed,
    result: { version },
  } = await safeGetLatestVersion()

  if (failed) return false

  const installedVersion = getRunningVersion()

  const { result: updating, success } = safeIsActiveLessThanLatest({
    activeVersion: installedVersion,
    latestVersion: version,
  })

  console.warn(
    `Update ${
      updating
        ? `found ${installedVersion} -> to -> ${version}`
        : `not found, on latest version: ${installedVersion}`
    }`
  )

  if (success) return updating
  return false
}

export const updateAppToLatestVersion = safePipe(
  safeGetLatestVersion,
  downloadLatestAppVersion,
  setAppToLatestVersion
)
