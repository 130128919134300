import { Component } from 'react'
import { Button, Spacer, Text, View } from '@cnd/elements'
import * as Sentry from '@sentry/nextjs'
import { absoluteFillObjectZ } from '@services/StyleSheet'
import { Capacitor } from '@capacitor/core'
import { CapacitorUpdater } from '@capgo/capacitor-updater'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    Sentry.captureException(error)
  }

  reload = () => {
    if (Capacitor.isNativePlatform()) {
      localStorage.clear()
      CapacitorUpdater.reload()
    } else {
      localStorage.clear()
      window.location.reload()
    }
  }

  render() {
    const state = this.state as any
    const props = this.props as any
    if (state.hasError)
      return (
        <View center style={{ ...absoluteFillObjectZ(100), backgroundColor: 'black' }}>
          <Text color="white" weight={400} style={{ maxWidth: 300, lineHeight: 1.4 }} center size={16}>
            There was a problem starting the app. Please try again.
          </Text>
          <Spacer height={30} />
          <Button style={{ alignSelf: 'auto' }} color="white" onClick={this.reload}>
            <Text weight={600} style={{ maxWidth: 300 }}>
              Try again
            </Text>
          </Button>
        </View>
      )
    return props.children
  }
}

export default ErrorBoundary
