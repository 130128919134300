import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { App as CapacitorApp } from '@capacitor/app'
import { useEffect, useState } from 'react'
import { usePrevious } from '@uidotdev/usehooks'
import RecheckingModal from './RecheckingModal'
import ThereWasAProblemUpdating from './ThereWasAProblemUpdating'
import { useTypedSelector } from '@store'
import {
  isOnPageWhereUpdateRuns,
  shouldTryToLoadNewUpdate,
  updateAppToLatestVersion,
} from '@services/version'
import { values } from 'ramda'

const AppUpdateOnStateChange = () => {
  const currentPage = useTypedSelector((state) => state.route.current)
  const sidebarData = useTypedSelector((state) => state.sidebarBottom.data)
  const [is, setIs] = useState({ rechecking: false, updating: false, error: false })
  const was = usePrevious(is)

  const updateIs = (newIs) => setIs({ ...is, ...newIs })

  useEffect(() => {
    if (!was?.rechecking && is.rechecking) {
      shouldTryToLoadNewUpdate()
        .then((tryUpdate) => {
          console.log({ tryUpdate })
          return updateIs({ error: false, rechecking: false, updating: tryUpdate })
        })
        .catch(() => {
          console.log('error checking version')
          updateIs({ error: true, checkng: false, updating: false })
        })
    }
    if (!was?.updating && is.updating) {
      updateAppToLatestVersion()
        .then(() => updateIs({ updating: false, error: false, checking: false }))
        .catch(() => {
          console.log('error updating version')
          updateIs({ updating: false, error: true, checking: false })
        })
    }
  }, [is, was])

  const onAppstateChangeRun = (state: any) => {
    if (isOnPageWhereUpdateRuns(currentPage) && values(sidebarData).length === 0) {
      if (state.isActive) updateIs({ rechecking: true })
      if (!state.isActive) updateIs({ rechecking: false })
    }
  }

  useEffect(() => {
    CapacitorApp.addListener('appStateChange', onAppstateChangeRun)
  }, [])

  if (is.error) return <ThereWasAProblemUpdating tryAgainFcn={() => CapacitorUpdater.reset()} />
  if (is.updating) return <RecheckingModal text="Updating..." />
  if (is.rechecking) return <RecheckingModal text="Checking for updates..." />

  return null
}

export default AppUpdateOnStateChange
