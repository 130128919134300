import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import menuTranslations from './menuTranslations'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      es: {
        translation: {
          ...menuTranslations.zh.translation,
          'Home of the Crispy Rolls': '香脆卷之家',
          STOP: '停',
          MENU: '菜单'
        }
      },
       de: {
        translation: {
          ...menuTranslations.zh.translation,
          'Home of the Crispy Rolls': '香脆卷之家',
          STOP: '停',
          MENU: '菜单'
        }
      },
       fr: {
        translation: {
          ...menuTranslations.zh.translation,
          'Home of the Crispy Rolls': '香脆卷之家',
          STOP: '停',
          MENU: '菜单'
        }
      },
      en: {
        translation: {
          'Home of the Crispy Rolls': 'Home of the Crispy Rolls',
          STOP: 'STOP',
          MENU: 'MENU',
          OVERPAYING: 'OVERPAYING',
          FOR: 'FOR',
          HOT: 'HOT',
          'TASTY FOOD.': 'TASTY FOOD.',
          'Get hand made tasty Asian': 'Get hand made tasty Asian',
          food: 'food',
          'with up to 50% off': 'with up to 50% off',
          high: 'high',
          'street and delivery': 'street and delivery',
          'app prices.': 'app prices.',
          reviews: 'reviews',
          "Your order has been successfully sent to the kitchen and we'll start cooking as soon as the time comes.":
            "Your order has been successfully sent to the kitchen and we'll start cooking as soon as the time comes.",
          "We're preparing your food.": "We're preparing your food.",
          'Your order is ready for you to collect.': 'Your order is ready for you to collect.',
          'Your order has been picked up! Any problems, questions or suggestions, reach out to us by tapping help below.':
            'Your order has been picked up! Any problems, questions or suggestions, reach out to us by tapping help below.',

          'Your order is ready for collection by one of our riders.':
            'Your order is ready for collection by one of our riders.',
          "Your order is on it's way to you!": "Your order is on it's way to you!",
          'Your order has been delivered! Any problems, questions or suggestions, reach out to us by tapping help below.':
            'Your order has been delivered! Any problems, questions or suggestions, reach out to us by tapping help below.',
          'Get directions': 'Get directions',
          "We'll soon be delivering to you at": "We'll soon be delivering to you at",
          'Notes: ': 'Notes: ',
          'Checking version': 'Checking version',
          'You are running v': 'You are running v',
          Popular: 'Popular',
          Burgers: 'Burgers',
          Curries: 'Curries',
          Poke: 'Poke',
          Bowls: 'Bowls',
          Bao: 'Bao',
          Sides: 'Sides',
          'Curries you can make your own': 'Curries you can make your own',
          'Fresh poke made your way, customise yours and add what you like!':
            'Fresh poke made your way, customise yours and add what you like!',
          'Bao bun burgs with some awesome fillings, imagine if KFC and Bao had a baby':
            'Bao bun burgs with some awesome fillings, imagine if KFC and Bao had a baby',
          'Giant sushi rolls with a crispy center, imagine if KFC and Sushi had a baby':
            'Giant sushi rolls with a crispy center, imagine if KFC and Sushi had a baby',
          Rolls: 'Rolls',
          'Crispy Rolls': 'Crispy Rolls',
          Sushi: 'Sushi',
          'Sushi Rolls': 'Sushi Rolls',
          Crispy: 'Crispy',
          'Crispy chicken and fries': 'Crispy chicken and fries',
          Rice: 'Rice',
          Drinks: 'Drinks',
          'Served with ice': 'Served with ice',
          'Freshly made at home. Served warm from the oven':
            'Freshly made at home. Served warm from the oven',
          Desserts: 'Desserts',
          'to your door': 'to your door',
          'Add delivery address': 'Add delivery address',
          'Deliver to': 'Deliver to',
          'For Our Neighbours': 'For Our Neighbours',
          'Your code': 'Your code',
          'Already used': 'Already used',
          'Incorrect Code': 'Incorrect Code',
          Applied: 'Applied',
          'Checking...': 'Checking...',
          'Add the tube': 'Add the tube',
          'Add the tube (+ £2.70)': 'Add the tube (+ £2.70)',
          "We're closed": "We're closed",
          "We're open!": "We're open!",
          "We're opening soon": "We're opening soon",
          'tap for opening hours': 'tap for opening hours',
          'Select Hours Worked': 'Select Hours Worked',
          'You are checking in for shift': 'You are checking in for shift',
          'Set check in time': 'Set check in time',
          'Set finish time': 'Set finish time',
          'Check In': 'Check In',
          'Check Out': 'Check Out',
          Update: 'Update',
          Save: 'Save',
          Select: 'Select',
          BASKET: 'BASKET',
          'START ORDER': 'START ORDER',
          'Delivery or Collection': 'Delivery or Collection',
          'What’s your postcode?': 'What’s your postcode?',
          'GO TO CHECKOUT': 'GO TO CHECKOUT',
          'Back to Menu': 'Back to Menu',
          '% off': '% off',
          'There was a problem updating the app.': 'There was a problem updating the app.',
          'Try again': 'Try again',
          'Please use the website www.myhiro.club to order for the next few days.':
            'Please use the website www.myhiro.club to order for the next few days.',
          "We're deploying a new menu and rewards to the app over the next few days which wont also work on the website so we've had to stop people ordering from old app versions.":
            "We're deploying a new menu and rewards to the app over the next few days which wont also work on the website so we've had to stop people ordering from old app versions.",
          'The Keeper of this relic is': 'The Keeper of this relic is',
          'Discovered on the ': 'Discovered on the ',
          'My Relics': 'My Relics',
          'You have discovered': 'You have discovered',
          'Seek out leaders in your community to uncover relics':
            'Seek out leaders in your community to uncover relics',
          DISCOVERING: 'DISCOVERING',
          'DISCOVER RELICS': 'DISCOVER RELICS',
          'View on map': 'View on map',
          'Collect From': 'Collect From',
          'min walk': 'min walk',
          'Pick up': 'Pick up',
          'Clear all data': 'Clear all data',
          'Collect order icon': 'Collect order icon',
          Menu: 'Menu',
          'My Orders': 'My Orders',
          Blog: 'Blog',
          'About us': 'About us',
          'Your Privacy': 'Your Privacy',
          Safety: 'Safety',
          'Contact us': 'Contact us',
          'On iOS? Get the App': 'On iOS? Get the App',
          'On Android? Get the App': 'On Android? Get the App',
          v: 'v',
          'e.g. WC2N 6NE': 'e.g. WC2N 6NE',
          Now: 'Now',
          Schedule: 'Schedule',
          'Deliver to me': 'Deliver to me',
          'Select date': 'Select date',
          select: 'select',
          'Select time': 'Select time',
          add: 'add',
          'delivery icon': 'delivery icon',
          Delivery: 'Delivery',
          now: 'now',
        },
      },
      it: {
        translation: {
          'Home of the Crispy Rolls': 'Casa dei Crispy Rolls',
          STOP: 'BASTA',
          MENU: 'MENU',
          OVERPAYING: 'STRAPAGARE',
          FOR: 'PER',
          HOT: 'RICCO',
          'TASTY FOOD.': 'CIBO GUSTOSO.',
          'Get hand made tasty Asian': 'Scegli delizioso cibo asiatico fatto ',
          food: 'a mano',
          'with up to 50% off': 'con sconti fino al 50%',
          high: '',
          'street and delivery': 'rispetto a prezzi di strada e',
          'app prices.': 'consegna a domicilio.',
          reviews: 'recensioni',
          "Your order has been successfully sent to the kitchen and we'll start cooking as soon as the time comes.":
            'Il tuo ordine è stato inviato con successo alla cucina e inizieremo a cucinare non appena sarà il momento.',
          "We're preparing your food.": 'Stiamo preparando il tuo cibo.',
          'Your order is ready for you to collect.': 'Il tuo ordine è pronto per essere ritirato.',
          'Your order has been picked up! Any problems, questions or suggestions, reach out to us by tapping help below.':
            'Il tuo ordine è stato ritirato! Per qualsiasi problema, domanda o suggerimento, contattaci toccando aiuto qui sotto.',
          'Your order is ready for collection by one of our riders.':
            'Il tuo ordine è pronto per essere ritirato da uno dei nostri corrieri.',
          "Your order is on it's way to you!": 'Il tuo ordine è in viaggio verso di te!',
          'Your order has been delivered! Any problems, questions or suggestions, reach out to us by tapping help below.':
            'Il tuo ordine è stato consegnato! Per qualsiasi problema, domanda o suggerimento, contattaci toccando aiuto qui sotto.',
          'Get directions': 'Ottieni indicazioni',
          "We'll soon be delivering to you at": 'Tra poco ti consegneremo a',
          'Notes: ': 'Note: ',
          'Checking version': 'Verifica versione',
          'You are running v': 'Stai eseguendo la v',
          Popular: 'Popolari',
          Burgers: 'Hamburger',
          Curries: 'Curry',
          Poke: 'Poke',
          Bowls: 'Ciotole',
          Bao: 'Bao',
          Sides: 'Contorni',
          'Curries you can make your own': 'Curry che puoi personalizzare',
          'Fresh poke made your way, customise yours and add what you like!':
            'Poke fresco fatto a tuo modo, personalizza il tuo e aggiungi ciò che preferisci!',
          'Bao bun burgs with some awesome fillings, imagine if KFC and Bao had a baby':
            'Bao con ripieni fantastici, immagina se KFC e Bao avessero un figlio',
          'Giant sushi rolls with a crispy center, imagine if KFC and Sushi had a baby':
            'Grandi rotoli di sushi con un centro croccante, immagina se KFC e Sushi avessero un figlio',
          Rolls: 'Rotoli',
          'Crispy Rolls': 'Rotoli Croccanti',
          Sushi: 'Sushi',
          'Sushi Rolls': 'Rotoli di Sushi',
          Crispy: 'Croccante',
          'Crispy chicken and fries': 'Pollo croccante e patatine',
          Rice: 'Riso',
          Drinks: 'Bevande',
          'Served with ice': 'Servito con ghiaccio',
          'Freshly made at home. Served warm from the oven': 'Fatto fresco in casa. Servito caldo dal forno',
          Desserts: 'Dolci',
          'to your door': 'alla tua porta',
          'Add delivery address': 'Aggiungi indirizzo di consegna',
          'Deliver to': 'Consegna a',
          'For Our Neighbours': 'Per i nostri vicini',
          'Your code': 'Il tuo codice',
          'Already used': 'Già utilizzato',
          'Incorrect Code': 'Codice non corretto',
          Applied: 'Applicato',
          'Checking...': 'Verifica in corso...',
          'Add the tube': 'Aggiungi la tubatura',
          'Add the tube (+ £2.70)': 'Aggiungi la tubatura (+ £2.70)',
          "We're closed": 'Siamo chiusi',
          "We're open!": 'Siamo aperti!',
          "We're opening soon": 'Apriremo presto',
          'tap for opening hours': 'tocca per gli orari di apertura',
          'Select Hours Worked': 'Seleziona ore lavorate',
          'You are checking in for shift': 'Stai facendo il check-in per il turno',
          'Set check in time': 'Imposta orario di check-in',
          'Set finish time': 'Imposta orario di fine',
          'Check In': 'Check-in',
          'Check Out': 'Check-out',
          Update: 'Aggiorna',
          Save: 'Salva',
          Select: 'Seleziona',
          BASKET: 'CESTO',
          'START ORDER': 'INIZIA ORDINE',
          'Delivery or Collection': 'Consegna o Ritiro',
          'What’s your postcode?': 'Qual è il tuo codice postale?',
          'GO TO CHECKOUT': 'VAI ALLA CASSA',
          'Back to Menu': 'Torna al menu',
          '% off': '% di sconto',
          'There was a problem updating the app.': "C'è stato un problema nell'aggiornare l'app.",
          'Try again': 'Prova ancora',
          'Please use the website www.myhiro.club to order for the next few days.':
            'Si prega di utilizzare il sito web www.myhiro.club per ordinare per i prossimi giorni.',
          "We're deploying a new menu and rewards to the app over the next few days which wont also work on the website so we've had to stop people ordering from old app versions.":
            "Stiamo implementando un nuovo menu e ricompense sull'app nei prossimi giorni, che non funzioneranno anche sul sito web, quindi abbiamo dovuto impedire alle persone di ordinare dalle vecchie versioni dell'app.",
          'The Keeper of this relic is lost, a Ghost with no remains':
            'Il Custode di questa reliquia è perduto, un Fantasma senza resti',
          'A vessel, a shackle, a land of forsaken beings':
            'Un vascello, una catena, una terra di esseri abbandonati',
          'Within it lies an ocean, within the ocean lies despair':
            "Al suo interno si trova un oceano, nell'oceano si trova la disperazione",
          'Within despair lies hope, within hope lies salvation':
            'Nella disperazione si trova la speranza, nella speranza si trova la salvezza',
          'Within salvation lies harmony, within harmony lies chaos':
            'Nella salvezza si trova l’armonia, nell’armonia si trova il caos',
          'The way is shut, the path is lost, yet there is always a way':
            'La via è chiusa, il sentiero è perduto, eppure c’è sempre una via',
          'The key is the beginning, the beginning is the end': 'La chiave è l’inizio, l’inizio è la fine',
          'The end is the beginning, the beginning is the key': 'La fine è l’inizio, l’inizio è la chiave',
          'To find the key is to unlock the path, to unlock the path is to find the key':
            'Trovare la chiave significa sbloccare il sentiero, sbloccare il sentiero significa trovare la chiave',
          'The path is a circle, the circle is the path':
            'Il sentiero è un cerchio, il cerchio è il sentiero',
          'A broken circle can be mended, a broken path can be repaired':
            'Un cerchio spezzato può essere risanato, un sentiero interrotto può essere riparato',
          'To mend the circle is to mend the path, to mend the path is to mend the circle':
            'Riparare il cerchio significa riparare il sentiero, riparare il sentiero significa riparare il cerchio',
          'In mending lies the key, in the key lies mending':
            'Nella riparazione si trova la chiave, nella chiave si trova la riparazione',
          'The key is harmony, harmony is the key': 'La chiave è l’armonia, l’armonia è la chiave',
        },
      },
      zh: {
        translation: {
          ...menuTranslations.zh.translation,
          'Home of the Crispy Rolls': '香脆卷之家',
          STOP: '停',
          MENU: '菜单',
          OVERPAYING: '过度支付',
          FOR: '为',
          HOT: '热的',
          'TASTY FOOD.': '美味的食物。',
          'Get hand made tasty Asian': '获取手工制作的美味亚洲',
          food: '食物',
          'with up to 50% off': '高达5折',
          high: '高',
          'street and delivery': '街道和配送',
          'app prices.': '应用价格。',
          reviews: '评论',
          "Your order has been successfully sent to the kitchen and we'll start cooking as soon as the time comes.":
            '您的订单已成功发送到厨房，我们将在时机成熟时开始烹饪。',
          "We're preparing your food.": '我们正在准备您的食物。',
          'Your order is ready for you to collect.': '您的订单已准备好供您取走。',
          'Your order has been picked up! Any problems, questions or suggestions, reach out to us by tapping help below.':
            '您的订单已被取走！如有任何问题、疑问或建议，请点击下方的帮助与我们联系。',
          'Your order is ready for collection by one of our riders.':
            '您的订单已由我们的一名骑手准备好供取货。',
          "Your order is on it's way to you!": '您的订单正在路上！',
          'Your order has been delivered! Any problems, questions or suggestions, reach out to us by tapping help below.':
            '您的订单已送达！如有任何问题、疑问或建议，请点击下方的帮助与我们联系。',
          'Get directions': '获取方向',
          "We'll soon be delivering to you at": '我们将很快向您送货',
          'Notes: ': '备注：',
          'Checking version': '检查版本',
          'You are running v': '您正在运行v',
          Popular: '热门',
          Burgers: '汉堡',
          Curries: '咖喱',
          Poke: '波克',
          Bowls: '碗',
          Bao: '包子',
          Sides: '配菜',
          'Curries you can make your own': '您可以自制的咖喱',
          'Fresh poke made your way, customise yours and add what you like!':
            '新鲜的波克由您决定，定制您的并添加您喜欢的！',
          'Bao bun burgs with some awesome fillings, imagine if KFC and Bao had a baby':
            '包含一些赞美的馅料的包子汉堡，想象如果肯德基和包子有了一个宝宝',
          'Giant sushi rolls with a crispy center, imagine if KFC and Sushi had a baby':
            '中心酥脆的巨型寿司卷，想象如果肯德基和寿司有了一个宝宝',
          Rolls: '卷',
          'Crispy Rolls': '香脆卷',
          Sushi: '寿司',
          'Sushi Rolls': '寿司卷',
          Crispy: '香脆',
          'Crispy chicken and fries': '香脆鸡肉和炸薯条',
          Rice: '米饭',
          Drinks: '饮料',
          'Served with ice': '配有冰',
          'Freshly made at home. Served warm from the oven': '家里新鲜制作。出炉即食',
          Desserts: '甜点',
          'to your door': '送到您的门前',
          'Add delivery address': '添加配送地址',
          'Deliver to': '送货到',
          'For Our Neighbours': '为我们的邻居',
          'Your code': '您的代码',
          'Already used': '已使用',
          'Incorrect Code': '代码错误',
          Applied: '已应用',
          'Checking...': '检查中...',
          'Add the tube': '添加管',
          'Add the tube (+ £2.70)': '添加管（+ £2.70）',
          "We're closed": '我们已关闭',
          "We're open!": '我们已开业！',
          "We're opening soon": '我们即将开业',
          'tap for opening hours': '点击查看营业时间',
          'Select Hours Worked': '选择工作时间',
          'You are checking in for shift': '您正在签到班次',
          'Set check in time': '设置签到时间',
          'Set finish time': '设置结束时间',
          'Check In': '签到',
          'Check Out': '签出',
          Update: '更新',
          Save: '保存',
          Select: '选择',
          BASKET: '购物篮',
          'Delivery or Collection': '配送或自取',
          'What’s your postcode?': '您的邮政编码是什么？',
          'GO TO CHECKOUT': '去结账',
          'Back to Menu': '返回菜单',
          '% off': '％ 折扣',
          'There was a problem updating the app.': '更新应用时出现问题。',
          'Try again': '再试一次',
          'Please use the website www.myhiro.club to order for the next few days.':
            '请在接下来的几天里使用网站www.myhiro.club进行订购。',
          "We're deploying a new menu and rewards to the app over the next few days which wont also work on the website so we've had to stop people ordering from old app versions.":
            '我们将在接下来的几天里将新菜单和奖励部署到应用上，这在网站上也无法工作，因此我们不得不阻止人们从',

          'The Keeper of this relic is': '这个遗物的守护者是',
          'Discovered on the ': '发现于 ',
          'My Relics': '我的遗物',
          'You have discovered': '你已发现',
          'Seek out leaders in your community to uncover relics': '在你的社区中寻找领导者，以揭示遗物',
          DISCOVERING: '发现中',
          'DISCOVER RELICS': '发现遗物',
          'View on map': '在地图上查看',
          'Collect From': '收集自',
          'min walk': '分钟步行',
          'Pick up': '取货',
          'Clear all data': '清除所有数据',
          'Collect order icon': '收集订单图标',
          Menu: '菜单',
          'My Orders': '我的订单',
          Blog: '博客',
          'About us': '关于我们',
          'Your Privacy': '你的隐私',
          Safety: '安全',
          'Contact us': '联系我们',
          'On iOS? Get the App': '使用iOS？获取应用',
          'On Android? Get the App': '使用Android？获取应用',
          v: 'v',
          'e.g. WC2N 6NE': '例如 WC2N 6NE',
          Now: '现在',
          Schedule: '计划',
          'Deliver to me': '送货给我',
          'Select date': '选择日期',
          select: '选择',
          'Select time': '选择时间',
          add: '添加',
          ADD: '添加',
          'START ORDER': '开始点餐',
          'delivery icon': '配送图标',
          Delivery: '配送',
          now: '现在',
          'Your favourite home made dishes': '你最喜欢的家常菜',
          'When and where': '何时何地',
          'Enter your postcode': '输入您的邮政编码',
          Deliver: '送货',
          Collect: '取货',
          'Delivery Date': '送货日期',
          today: '今天',
          tomorrow: '明天',
          Monday: '星期一',
          Tuesday: '星期二',
          Wednesday: '星期三',
          Thursday: '星期四',
          Friday: '星期五',
          Saturday: '星期六',
          Sunday: '星期日',
          in: '',
          days: '天后',
          'Delivery Time': '送货时间',
          'Delivery Address': '送货地址',
          pick: '选择',
          am: '上午',
          pm: '下午',
          'No times available, please select another day.': '没有可用的时间，请选择另一天',
          midday: '中午',
          change: '更改',
          'Opening hours': '营业时间',
          close: '关闭',
          closed: '已关门',
          'You will be collecting from': '您将从这里取货',
          Confirm: '确认',
          Collection: '取货',
          CHECKOUT: '结账',
          'food total': '食物总计',
          'Place Order': '下单',
          'Collect from': '取货自',
          "We'll notify you by": '我们将通过',
          Cutlery: '餐具',
          'Your items': '您的物品',
          'Sub total': '小计',
          'Delivery fee': '配送费',
          'total payment': '总付款',
          'Payment method': '付款方式',
          selected: '已选择',
          'Add payment method': '添加付款方式',
          'mins walk': '分钟步行',
          'Phone number': '电话号码',
          Email: '电子邮件',
          'Just say if you need napkins and cutlery': '如果您需要餐巾纸和餐具，请告诉我们',
          'Are you sure you want to remove your payment method?': '您确定要删除您的付款方式吗?',
          'PLACING ORDER': '下单中',
          'Pay with card': '用卡付款',
          'Select payment method': '选择付款方式',
          from: '来自',
          to: '到',
          Logout: '登出',
          'Learn more': '了解更多',
        },
      },
    },
  })

export default i18n
