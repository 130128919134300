import * as Sentry from '@sentry/nextjs'
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

export const pageview = () => {
  window['fbq']('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = async (name, options = {}) => {
  try {
    await window['fbq']('track', name, options)
  } catch (error) {
    Sentry.captureException(error)
    console.log('Failed to do facebook Purchase event')
  }
}
