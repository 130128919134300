import useUser from '@store/useUser'
import api from '@services/api'
import { useCallback } from 'react'
import { User } from '@cnd/common/types'
import * as Sentry from '@sentry/nextjs'

const fakeLog = (id) => (name, payload) => {
  if (!process.env.PRODUCTION) console.info(`tracking event for ${id}:`, { name, payload })
}

export const shouldTrackUser = ({ userId, dev, hideInsights }: Partial<User>) =>
  userId && !dev && !hideInsights && process.env.PRODUCTION

export const logEvent = (userId, name, payload) => {
  try {
    api.post('/log', { userId, name, payload })
  } catch (error) {
    Sentry.captureException(error)
  }
}

const useInsights = () => {
  const userId = useUser('userId')
  const dev = useUser('dev')
  const hideInsights = useUser('hideInsights')
  const shouldTrack = process.env.PRODUCTION && !dev && !hideInsights

  const log = useCallback(
    async (name, payload = {}) => {
      try {
        console.info('real event:', { name, payload })
        logEvent(userId, name, payload)
      } catch (error) {}
    },
    [userId]
  )

  if (!userId) return { enabled: false, log: fakeLog(`_no one_`) }
  if (!shouldTrack) return { enabled: false, log: fakeLog(userId.substring(0, 8)) }
  return { enabled: true, log }
}

export default useInsights
