import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import promiseMiddleware from 'redux-promise-middleware'

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { asyncUserBootstrapMiddleware } from './userMiddleware'
import { clear, save } from 'redux-localstorage-simple'
import { flatten, map, mapObjIndexed, mergeDeepRight, reduce, values } from 'ramda'
import { createReducer } from '@cnd/redash/dist/funstore'
import initialConfiguration, { ACTION_NAMES, ActionPayload } from './initialConfiguration'
import initialState, { InitialState } from './initialState'
import { isWebsite } from '@services/platform'
import { Equals } from '@cnd/redash/dist/safeTry/safeTry'
import { firebaseListenerMiddleware } from './useFirebaseConnect'
import { backgroundActionsMiddleware } from './backgroundActionsMiddleware'
import { formatISO, isBefore, set } from 'date-fns'
import { getDerivedBusinessHours } from './useBusinessHours'
import { difference } from '@cnd/redash'
import { calculateDeliveryTimeRange } from '@cnd/common/functions/delivery'

export const useTypedSelector: TypedUseSelectorHook<InitialState> = useSelector

export type ActionParams = {
  type: ACTION_NAMES
  payload?: any
}
type Dispatch = <TReturnType>(action: ActionParams) => TReturnType
export const useTypedDispatch = () => useDispatch<Dispatch>()

// type Payloads<ActionName extends ACTION_NAMES | ActionParams> =
//   ActionName extends keyof ACTION_NAMES_BY_STATE_PARTIAL ? ACTION_NAMES_BY_STATE_PARTIAL[ActionName] : never

// Payloads<ActionName>

export const useFunDispatch = () => {
  const dispatch = useDispatch<Dispatch>()

  function dispatcher<ActionName extends ACTION_NAMES, T = ActionPayload<ActionName>>(
    type: ActionName
  ): (payload?: T) => any
  function dispatcher<ActionName extends ACTION_NAMES>(
    type: ActionName,
    payload?: ActionPayload<ActionName>
  ): any
  function dispatcher<ActionName extends ACTION_NAMES, T = ActionPayload<ActionName>>(
    type: ActionName,
    payload?: T
  ): Equals<T, undefined> extends true ? (payload?: T) => any : any {
    if (arguments.length === 1) return (payload?: ActionPayload<ActionName>) => dispatch({ type, payload })
    return dispatch({ type, payload })
  }

  return dispatcher
}

const middleware = [
  thunk,
  promiseMiddleware,
  asyncUserBootstrapMiddleware,
  firebaseListenerMiddleware([]),
  backgroundActionsMiddleware([{ actionType: 'SYNC_BUSINESS_HOURS_WITH_USER_TIME', interval: 25000 }]),
]

if (isWebsite) {
  const logger = createLogger({ diff: true })
  middleware.push(logger)
}

middleware.push(save({ namespace: 'root' }))

const appReducer = combineReducers(mapObjIndexed(createReducer, initialConfiguration))

const flatenPreorderDeliveryDaysToDateTimes = (deliveryDays) =>
  flatten(
    reduce(
      (times, { timeSlots, isoDate }) => {
        return [
          ...times,
          map(({ hour, minutes }) => formatISO(set(new Date(isoDate), { hours: hour, minutes })), timeSlots),
        ]
      },
      [],
      deliveryDays
    ) as any
  )

const rootReducer = (state: InitialState, action) => {
  if (action.type === 'CLEAR_STATE') {
    clear({ namespace: 'root' })
    return appReducer(initialState, action)
  }

  if (action.type === 'SET_PERSISTED_STATE') {
    return appReducer(mergeDeepRight(initialState, action.payload), action)
  }

  if (action.type === 'SYNC_BUSINESS_HOURS_WITH_USER_TIME') {
    const closestToUser = state.locations?.closestToUser
    const searchPreOrder = state.locations?.searchPreOrder
    let locationsState = state.locations

    if (closestToUser) {
      const newClosestToUser = getDerivedBusinessHours(closestToUser)
      const newLocationsState = {
        ...locationsState,
        closestToUser: newClosestToUser,
        searchPreOrder: newClosestToUser.isShopClosed ? true : searchPreOrder,
      }

      const diff = difference(newLocationsState, state)
      if (values(diff).length !== 0) locationsState = newLocationsState
    }

    // if basket is set to old now for a time that is no longer available, set the basket to now now
    // if basket is pre booked for a time that is no longer available, clear the basket and set to now now
    // if basket has never been set, just return with updated business hours

    const orderState = state?.user?.order

    const expectedDeliveryTimeFrom = orderState?.expectedDeliveryTimeFrom
    const isShopClosed = locationsState?.closestToUser?.isShopClosed

    console.log({
      isBefore: isBefore(new Date(expectedDeliveryTimeFrom), new Date()),
      closestToUser: closestToUser,
      isShopClosed: !isShopClosed,
      'orderState.deliveryLocation': orderState.deliveryLocation,
    })

    if (
      isBefore(new Date(expectedDeliveryTimeFrom), new Date()) &&
      closestToUser &&
      !isShopClosed &&
      orderState.deliveryLocation
    ) {
      // set to delivery now
      return appReducer(
        mergeDeepRight(state, {
          locations: locationsState,
          user: {
            order: {
              whenAndWhereSet: true,
              isCollection: false,
              isPreOrder: false,
              collectionLocation: null,
              prepLocation: closestToUser,
              ...calculateDeliveryTimeRange(orderState?.items),
            },
          },
        }),
        action
      )
    }

    if (isBefore(new Date(expectedDeliveryTimeFrom), new Date()) && closestToUser && isShopClosed) {
      // set to delivery now
      console.log('this')
      return appReducer(
        mergeDeepRight(state, {
          locations: !!state.locations.choosingLocation
            ? locationsState
            : {
                ...locationsState,
                searchExpectedDeliveryTimeFrom: null,
                searchExpectedDeliveryTimeTo: null,
              },
          user: {
            order: {
              whenAndWhereSet: false,
              isCollection: null,
              isPreOrder: null,
              collectionLocation: null,
              prepLocation: closestToUser,
              expectedDeliveryTimeFrom: null,
              expectedDeliveryTimeTo: null,
            },
          },
        }),
        action
      )
    }

    return appReducer(state, action)
  }

  return appReducer(state, action)
}

export default createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)))
