import { Keyboard, KeyboardResize } from '@capacitor/keyboard'
import { isApp } from '@services/platform'
import { isNull } from 'lodash'
import { useEffect, useState } from 'react'
import { singletonHook } from 'react-singleton-hook'

const KeyboardMock = {
  hide: () => null,
  show: () => null,
  setAccessoryBarVisible: () => null,
  setScroll: () => null,
  setStyle: () => null,
  setResizeMode: () => null,
  getResizeMode: () => null,
  addListener: () => null,
  removeAllListeners: () => null,
}

const state = {
  configured: null,
  available: isApp,
  Keyboard: isApp ? Keyboard : KeyboardMock,
}

const useKeyboard = () => {
  const [keyboard, setKeyboard] = useState(state)

  const configureKeyboard = async () => {
    try {
      if (keyboard.available) {
        await Keyboard.setResizeMode({ mode: KeyboardResize.None })
      }
      setKeyboard({ ...keyboard, configured: true })
    } catch (error) {
      setKeyboard({ ...keyboard, configured: false })
    }
  }

  useEffect(() => {
    configureKeyboard()
  }, [])

  return keyboard
}

export const SetupKeyboardOnMobile = ({ loading, children }) => {
  const { configured } = useKeyboard()
  return isNull(configured) ? loading : children
}

export default singletonHook(state, useKeyboard)
