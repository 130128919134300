import { cleanReadableId, uuid } from '@cnd/redash'
import initialState from './initialState'
import { Order } from '@cnd/common/types'
import { isValidDeliveryLocation } from '@cnd/common/functions/payment'
import { formatISO, getTime } from 'date-fns'

export const CURRENT_ORDER_VERSION = 3

const createOrder = (membership, defaultDeliveryAddress): Order => {
  const createdAt = new Date()
  return {
    ...initialState.user.order,
    version: CURRENT_ORDER_VERSION,
    id: uuid(),
    humanId: cleanReadableId(),
    createdAtTimestamp: formatISO(createdAt),
    createdAtUnixTimestamp: getTime(createdAt),
    membership,
    deliveryLocation: isValidDeliveryLocation(defaultDeliveryAddress) ? defaultDeliveryAddress : null,
    statesHistory: {
      [formatISO(createdAt)]: {
        state: 'NEW',
        createdAtTimestamp: formatISO(createdAt),
        createdAtUnixTimestamp: getTime(createdAt),
      },
    },
  }
}
export default createOrder
