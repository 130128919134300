const menuTranslations = {
  zh: {
    translation: {
      'Cozy Classics': '温馨经典',
      'Dishes you love that you can make your own': '你喜爱的菜肴，你可以自己制作',
      'Mango Masala Curry': '芒果玛萨拉咖喱',
      'Rich home made mango masala curry sauce, with your choice of protein and rice.':
        '浓郁自制芒果玛萨拉咖喱酱，可搭配您选择的蛋白质和米饭。',
      Fat: '脂肪',
      Carbs: '碳水化合物',
      Protein: '蛋白质',
      kcal: '千卡',
      kJ: '千焦',
      g: '克',
      'Which Protein?': '选择哪种蛋白质？',
      'Crispy Southern Chicken': '香脆南方鸡',
      'Crispy southern breaded chicken pieces': '香脆南方裹粉鸡块',
      'Crispy Classic Chicken': '经典香脆鸡',
      'Crispy classic breaded chicken pieces': '经典香脆裹粉鸡块',
      'Fish Fingers': '鱼指',
      'Crispy classic fish fingers': '经典香脆鱼指',
      'Crispy Falafel': '香脆法拉费尔',
      'Spheres of crispy fried falafel': '香脆油炸法拉费尔球',
      'Crispy Prawns': '香脆虾',
      'Jumbo breaded crispy prawns': '特大裹粉香脆虾',
      'Crispy Vegan Chicken': '香脆纯素鸡',
      'Your way': '您的方式',
      'Crispy vegan breaded chicken': '香脆纯素裹粉鸡',
      'Sticky White Rice': '糯米饭',
      'Cauliflower Rice': '花椰菜米',
      'Extra sauce?': '需要额外的酱汁吗?',
      Medium: '中等',
      'Classic size, perfect for most': '经典尺寸，适合大多数人',
      Large: '大号',
      'For the animal within': '满足内在的动物本能',
      Customise: '定制',
      'Katsu Curry': '勝つ咖喱',
      'Rich home made katsu curry sauce cooked the way you like it, with your choice of protein and rice.':
        '浓郁自制勝つ咖喱酱，按您喜欢的方式烹饪，搭配您选择的蛋白质和米饭',
      Smooth: '顺滑',
      'blended & creamy': '混合且奶油般细腻',
      Loaded: '材料丰富的',
      'tasty pieces of carrot & potato': '美味的胡萝卜和土豆片',
      'How Spicy?': '多辣？',
      Mild: '微辣',
      Hot: '辣',
      'Thai Red Curry': '泰式红咖喱',
      'Rich and spicy home made thai red curry sauce, served with rice.':
        '浓郁辣味自制泰式红咖喱酱，配米饭上桌。',
      'Grilled Chicken': '烤鸡',
      'Thai Green Curry': '泰式绿咖喱',
      'Rich and spicy home made thai green curry sauce, served with rice.':
        '浓郁辣味自制泰式绿咖喱酱，配米饭上桌。',
      'Chilli Con Carne': '辣椒牛肉',
      'Rich home made chilli con carne, with your choice of rice.': '浓郁自制辣椒牛肉，可搭配您选择的米饭。',
      'Poke Bowl': '波奇碗',
      'Light cold rice bowl with crispy proteins and all the good vegetables.':
        '清爽冷米饭碗，搭配香脆蛋白质和各种美味蔬菜。',
      Vegan: '纯素食者',
      Veggie: '素食者',
      Halal: '清真',
      'Ice Cream': '冰淇淋',
      'Jumbo Prawn Roll': '特大虾卷',
      'Served with a cup with ice.': '配有一杯冰。',

      'Bao Burgers': '包子汉堡',
      'Fish Bun Burger': '鱼汉堡包',
      'Crispy fish fingers cooked until perfectly golden and wrapped in crisp fresh lettuce in a steamed bao bun. Complete with a perfect serving of sweet chilli sauce.':
        '金黄酥脆的鱼柳，包裹在新鲜的脆生菜中，放在蒸制的包子里。搭配完美的甜辣酱一起享用。',
      'Chicken Bun Burger': '鸡肉汉堡包',
      'Crispy chicken fillet cooked until perfectly golden and wrapped in crisp fresh lettuce in a steamed bao bun. Complete with a perfect serving of sweet chilli sauce.':
        '金黄酥脆的鸡柳，包裹在新鲜的脆生菜中，放在蒸制的包子里。搭配完美的甜辣酱一起享用。',
      'Bacon Bun Burger': '培根汉堡包',
      'Delicious turkey bacon rashes cooked until perfectly crispy and wrapped in crisp fresh lettuce in a steamed bao bun. Complete with a perfect serving of sweet chilli sauce.':
        '美味的火鸡培根薄片烹饪至完美的酥脆，包裹在新鲜的脆生菜中，放在蒸制的包子里。搭配完美的甜辣酱一起享用。',
      'Sausage Bun Burger': '香肠汉堡包',
      'Delicious pan fried beef sausages with a crispy outside, wrapped in crisp fresh lettuce in a steamed bao bun. Complete with a perfect serving of sweet chilli sauce.':
        '美味的煎炸牛肉香肠，外脆内嫩，包裹在新鲜的脆生菜中，放在蒸制的包子里。搭配完美的甜辣酱一起享用。',
      'Plant Based Sausage Bun Burger': '植物性香肠汉堡包',
      'Delicious vegan sausages cooked until perfectly golden and wrapped in crisp fresh lettuce in a steamed bao bun. Complete with a perfect serving of sweet chilli sauce.':
        '美味的纯素食香肠，烹饪至完美的金黄色，包裹在新鲜的脆生菜中，放在蒸制的包子里。搭配完美的甜辣酱一起享用。',
      'Prawn Bun Burger': '虾汉堡包',
      'Crispy breaded prawns cooked until perfectly golden with salad, sauce in a steamed bao bun.':
        '金黄色的面包屑虾炸至完美，配以沙拉、酱汁，夹在蒸制的包子中。',
      'Falafel Roll': '油炸豆丸卷',
      'Crispy falafel spheres wrapped in crisp fresh lettuce and sushi rice. Complete with sweet chilli sauce.':
        '金黄色的油炸沙拉饭团包裹着脆脆的沙拉和寿司饭，搭配甜辣酱一起享用。',
      'Vegan Chicken Roll': '纯素鸡肉卷',
      'Chunks of crispy vegan chicken cooked until perfectly crispy and wrapped in crisp fresh lettuce and sushi rice. Complete with a perfect serving of sweet chilli sauce.':
        '金黄色的纯素鸡块炸至完美，包裹在脆脆的生菜和寿司饭中，搭配完美的甜辣酱一起享用。',
      'Chicken Roll': '鸡肉卷',
      'Chunks of crispy chicken breast cooked until perfectly crispy and wrapped in crisp fresh lettuce and sushi rice. Complete with a perfect serving of sweet chilli sauce.':
        '金黄色的鸡胸块炸至完美，包裹在脆脆的生菜和寿司饭中，搭配完美的甜辣酱一起享用。',
      'Breakfast Roll': '早餐卷',
      'Crispy bacon, crispy potato, egg and brown sauce wrapped in sushi rice.':
        '金黄色的培根、脆脆的土豆、鸡蛋和褐色酱包裹在寿司饭中。',
      'Fish Roll': '鱼卷',
      'Yummy crispy fish fingers wrapped in crisp fresh lettuce and sushi rice. Complete with sweet chilli sauce.':
        '美味的酥脆鱼柳块包裹在脆脆的生菜和寿司饭中。搭配甜辣酱一起享用。',
      'Breaded crispy jumbo prawns wrapped in crisp fresh lettuce and sushi rice. Complete with sweet chilli sauce.':
        '面包屑外脆内嫩的巨大虾仁包裹在脆脆的生菜和寿司饭中。搭配甜辣酱一起享用。',
      Samosa: '咖喱角',
      '2 x Perfect pyramid shaped veggie Punjab samosas.': '2个完美的金字塔形状的素食旁遮普三角饼。',
      'Crispy Fries': '脆脆的薯条',
      'Yes, you can load them!': '是的，你可以装载它们！',
      'Curly Fries': '卷曲薯条',
      'Yes, you can load these too!': '是的，你也可以装载这些！',
      'Plant Based Tenders': '纯素鸡柳',
      'Chicken Tenders': '鸡柳',
      'Lightly southern spiced.': '轻轻的南方香料。',
      'Rice 🇯🇵': '米饭',
      'Sushi Rice': '米饭',
      'Sushi rice': '米饭',
      'White and perfect with Katsu Curry': '白色的，非常适合咖喱煎猪排饭',
      Water: '水',
      'Sparkling Water': '气泡水',
      'Fanta Zero': 'Fanta Zero',
      'Coke Zero': 'Coke Zero',
      'San Pellegrino Orange': 'San Pellegrino Orange',
      'San Pellegrino Lemon': 'San Pellegrino Lemon',
      'San Pellegrino Blood Orange': 'San Pellegrino Blood Orange',
      'All baked treats are served warm from the oven': '所有烘焙美食都是从烤箱里热腾腾地上桌的。',
      'Brownie bites': '布朗尼小块',
      '3 Chewy and gooey homemade warm double chocolate chip brownie bites with chocolate buttons.':
        '3颗嚼劲十足、浓郁多汁的自制温暖双巧克力芯布朗尼小块，配有巧克力按钮。',
      Cookie: '饼干',
      'A chewy and gooey homemade double chocolate chip cookie made with chocolate buttons and browned butter giving it a sweet caramel taste.':
        '嚼劲十足、浓醇多汁的自制双巧克力芯饼干，由巧克力按钮和炒黄油制成，赋予它甜美的焦糖风味。',
      'Dark Cookie': '黑巧克力饼干',
      'A chewy and gooey freshly baked double chocolate chip cookie made with chocolate dough and chocolate buttons.':
        '一款嚼劲十足、浓郁多汁的新鲜烤制双巧克力芯饼干，由巧克力面团和巧克力按钮制成。',
      'Cookie Dough': '曲奇面团',
      'Delicious double chocolate chip cookie dough made with chocolate buttons and browned butter giving it a sweet caramel taste.':
        '美味的双巧克力芯饼干面团，由巧克力按钮和炒黄油制成，赋予其甜美的焦糖味道。',
      'Ice cream': '冰淇淋 ',
      'Perfect with our cookies and brownies!': '与我们的饼干和布朗尼搭配完美！',
      'Fudge Brownie Ice Cream 🇺🇸': '巧克力布朗尼冰淇淋',
      'Delicious Chocolate ice cream with chocolate brownie pieces.':
        '美味的巧克力冰淇淋，带有巧克力布朗尼块。',
      'Cookie Dough Ice Cream 🇺🇸': '曲奇冰淇淋',
      'Delicious Vanilla ice cream with chunks of chocolate chip cookie dough and chocolatey bits.':
        '美味的香草冰淇淋，带有巧克力芯曲奇面团块和巧克力碎片。',
      'Strawberry Cheesecake Ice Cream 🇺🇸': '草莓芝士蛋糕冰淇淋 ',
      'Delicious Strawberry cheesecake ice cream with sweetened strawberry pieces and a cookie swirl.':
        '美味的草莓芝士蛋糕冰淇淋，带有甜草莓块和曲奇漩涡。',
      'Vanilla Ice Cream 🇺🇸': '香草冰淇淋',
      'Simple and delicious vanilla ice cream.': '简单而美味的香草冰淇淋 ',
      'Non Dairy Cookie Ice Cream 🇺🇸': '非乳制品曲奇冰淇淋',
      'Non-dairy caramel ice cream with a cookie swirl and chocolate chip cookie dough.':
        '不含乳制品的焦糖冰淇淋，带有曲奇漩涡和巧克力曲奇面团。',
      'Pick your carb': '选择你的碳水化合物',
      'Grilled chicken': '烤鸡肉',
      'More Protein?': '更多蛋白质?',
      'Which Carb?': '哪种碳水化合物?',
      'What base?': '选择哪种基底？',
      'Lettuce base': '生菜基底',
      'Cauliflower rice': '花椰菜米',
      'Crispy Breaded Chicken': '香脆裹粉鸡肉',
      'crispy fried breaded chicken pieces': '香脆裹粉鸡肉块',
      'crispy classic fish fingers': '香脆经典鱼柳',
      'spheres of crispy fried falafel': '香脆油炸法拉费尔球',
      'crispy fried breaded vegan chicken pieces': '香脆裹粉纯素鸡肉块',

      'Spiced Crispy Prawns': '香辣香脆虾',
      'Spiced breaded crispy king prawns': '香辣裹粉香脆大虾',

      'Fresh Water Prawns': '新鲜水虾',
      'fresh water prawns': '新鲜水虾',

      'Filling quick add': '填充快速添加',
      Cucumber: '黄瓜',
      Lettuce: '生菜',
      Carrots: '胡萝卜',
      Avocado: '鳄梨',
      Mango: '芒果',
      Pineapple: '菠萝',
      'Spring onions': '葱',
      'Red chili': '红辣椒',
      Edamame: '毛豆',
      Sweetcorn: '玉米',
      'Boiled egg': '煮鸡蛋',
      'Sesame Seeds': '芝麻',
      'Walnut Pieces': '核桃碎片',
      'Flaked Almonds': '杏仁片',
      'Crispy Onions': '脆洋葱',
      'Doritos Chilli': '多利多辣椒',
      'Doritos Cheese': '多利多奶酪',
      Seaweed: '海苔',

      'What sauce?': '选择哪种酱汁？',
      'Sweet Chilli': '甜辣酱',
      'Spicy Mayo': '辣椒酱',
      Sriracha: '是拉差',
      Mayonnaise: '蛋黄酱',
      'Soy Sauce': '酱油',
      'Tartare Sauce': '塔塔尔酱',
      'Olive Oil': '橄榄油',
      'Balsamic Vinegar': '香醋',
      'Teriyaki Sauce': '照烧酱',
      Ketchup: '番茄酱',
      'Brown Sauce': '棕色酱',
      'BBQ Sauce': '烧烤酱',
      BBQSauce: '烧烤酱',
      'Quick add': '快速添加',
      Bacon: '培根',
      'American Cheese Slice': '美式芝士片',
      'Max it up?': '最大化？',
      'Add Katsu Gravy': '添加咖喱酱汁',
      'NO Katsu Gravy': '没有咖喱酱汁',
      'How many?': '多少？',
      'Katsu Gravy': '咖喱酱汁',
    },
  },
} as const

export default menuTranslations
