export const getHasLaunched = (launch) => (launch - new Date().valueOf() < 0 ? true : false)

export const getIsBetweenStoreOpeningHours = ({ open: openingTime, close: closingTime }) => {
  if (!openingTime || !closingTime) return false

  const now = new Date()
  const open = new Date()
  const close = new Date()

  const openSplit = openingTime.split(':')
  const closeSplit = closingTime.split(':')

  open.setHours(openSplit[0])
  open.setMinutes(openSplit[1])
  open.setSeconds(0)
  open.setMilliseconds(0)

  close.setHours(closeSplit[0])
  close.setMinutes(closeSplit[1])
  close.setSeconds(0)
  close.setMilliseconds(0)

  // if closing time is actually tomorrow then add 1 day
  if (closeSplit[0] < openSplit[0]) close.setDate(now.getDate() + 1)

  if (now.getTime() < open.getTime() || now.getTime() > close.getTime()) return false

  return true
}
