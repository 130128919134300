import { initializeApp } from 'firebase/app'
import firebase from 'firebase/compat/app'
import { getDatabase, get as getFirebaseItem, ref } from 'firebase/database'
import 'firebase/analytics'
import 'firebase/storage'
import { getFirestore } from 'firebase/firestore'

var firebaseConfig = {
  apiKey: process.env.FIREBASE_apiKey,
  authDomain: process.env.FIREBASE_authDomain,
  databaseURL: process.env.FIREBASE_databaseURL,
  projectId: process.env.FIREBASE_projectId,
  storageBucket: process.env.FIREBASE_storageBucket,
  messagingSenderId: process.env.FIREBASE_messagingSenderId,
  appId: process.env.FIREBASE_appId,
  measurementId: process.env.FIREBASE_measurementId,
}

const app = initializeApp(firebaseConfig)
const db = getDatabase(app)

export const get = async (query) => (await getFirebaseItem(query)).toJSON()
export const dbRef = (path) => ref(db, path)
export const firestore = getFirestore(app)

export default firebase
