import { Spacer, Text, View } from '@cnd/elements'
import { useTranslation } from 'react-i18next'

const ThereWasAProblemUpdating = ({ tryAgainFcn }) => {
  const { t } = useTranslation()

  return (
    <View
      center
      style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'black' }}
    >
      <View center style={{ maxWidth: 220 }}>
        <Text center color="white" bold size={16}>
          {t('There was a problem updating the app.')}
        </Text>
        <Spacer height={35} />
        <View center>
          <View style={{ padding: 10, backgroundColor: 'white', borderRadius: 15 }} onClick={tryAgainFcn}>
            <Text>{t('Try again')}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default ThereWasAProblemUpdating
