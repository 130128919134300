import React, { useEffect } from 'react'
import { config } from '@fortawesome/fontawesome-svg-core'
import type { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import 'react-phone-number-input/style.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { AnimatePresence } from '@cnd/elements'

import '@components/I18n'

import { SingletonHooksContainer } from 'react-singleton-hook'
import { FB_PIXEL_ID, pageview } from '@services/facebook'
import { SplashScreen } from '@capacitor/splash-screen'

import '@components/css.css'
import store from '@store'
import '@fortawesome/fontawesome-svg-core/styles.css'
import '@components/semantic.min.css'

import { Splash } from '@components/Splash'
import ReactQueryWrapper from '@components/ReactQueryWrapper'
import Persist from '@store/Persist'

import { StatusBar, Style } from '@capacitor/status-bar'

import Script from 'next/script'
import { useRouter } from 'next/router'
import { UpdateOnLaunch, UpdateOnStateChange } from '@components/Updater'
import { SetupKeyboardOnMobile } from '@hooks/useKeyboard'

import { defineCustomElements } from '@stripe-elements/stripe-elements/loader'
import Track from '@components/Track'
import { API_BASE_URL } from '@services/api'
import { STRIPE_ENV } from '@constants/stripe'
import useRoute from '@hooks/useRoute'
import { isApp } from '@services/platform'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import LanguagePicker from '@components/I18n/LanguagePicker'
import ErrorBoundary from '@components/ErrorBoundary'

console.log({ FB_PIXEL_ID })

const forceLogout = () => {
  localStorage.clear()
  window.location.reload()
}

console.info(`Server Configuration`, {
  PAYMENT_MODE: STRIPE_ENV,
  BUILD_ENV: process.env.BUILD_ENV,
  PRODUCTION: process.env.PRODUCTION,
  DEVELOPMENT: process.env.DEVELOPMENT,
  NODE_ENV: process.env.NODE_ENV,
  USER_DEV: process.env.USER_DEV,
  API_BASE_URL,
})

config.autoAddCss = false

const SetStatusBar = () => {
  const app_statusbar = async () => {
    await StatusBar.setStyle({ style: Style.Light })
  }
  useEffect(() => {
    if (isApp) app_statusbar()
  }, [])
  return null
}

const LinkedIn = () => {
  return (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            _linkedin_partner_id = "2759900";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);    
          `,
        }}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})(window.lintrk);
          `,
        }}
      />
    </>
  )
}

const TikTok = () => {
  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
          
            ttq.load('CLAJ263C77U0SITNCMBG');
            ttq.page();
          }(window, document, 'ttq');
        `,
      }}
    />
  )
}

const Facebook = () => {
  const router = useRouter()
  useEffect(() => {
    pageview()

    const handleRouteChange = () => {
      pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <Script
      id="fb-pixel"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
          `,
      }}
    />
  )
}

const RouterEvents = () => {
  useRoute()
  return null
}

const App = ({ Component, pageProps, router }: AppProps) => {
  useEffect(() => {
    if (window) {
      defineCustomElements(window)
      window['forceLogout'] = forceLogout
    }
    if (isApp) {
      SplashScreen.hide()
      CapacitorUpdater.notifyAppReady()
    }
  }, [])
  return (
    <ErrorBoundary>
      <UpdateOnLaunch>
        <SetStatusBar />
        <Provider store={store}>
          <Persist loading={<Splash />}>
            <RouterEvents />
            <ReactQueryWrapper>
              <LanguagePicker>
                <UpdateOnStateChange />
                <Track />
                <Facebook />
                <LinkedIn />
                <TikTok />
                <SingletonHooksContainer />
                <SetupKeyboardOnMobile loading={<Splash />}>
                  <AnimatePresence mode="wait" initial={false}>
                    <Component {...pageProps} key={router.asPath} />
                  </AnimatePresence>
                </SetupKeyboardOnMobile>
              </LanguagePicker>
            </ReactQueryWrapper>
          </Persist>
        </Provider>
      </UpdateOnLaunch>
    </ErrorBoundary>
  )
}

export default App
