const createBackgroundAction = (backgroundAction) => {
  let isRunning = false
  return (store) => {
    if (isRunning) return

    const { actionType, interval } = backgroundAction
    isRunning = true
    console.log(`Setting up background action ${actionType}`)
    setInterval(() => {
      console.log(`Running background action ${actionType}`)
      store.dispatch({ type: actionType })
    }, interval)
  }
}

export const backgroundActionsMiddleware = (backgroundActionDescriptions) => {
  let backgroundActions = []

  for (const backgroundActionDescription of backgroundActionDescriptions) {
    backgroundActions.push(createBackgroundAction(backgroundActionDescription))
  }

  return (store) => (next) => (action) => {
    for (const backgroundAction of backgroundActions) {
      backgroundAction(store)
    }

    return next(action)
  }
}
