import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { useEffect, useState } from 'react'
import { Spacer, Text, View } from '@cnd/elements'
import { Splash } from '../Splash'

import { usePrevious } from '@uidotdev/usehooks'
import Image from 'next/image'
import { isApp } from '@services/platform'
import {
  EARLIEST_SUPPORTED_VERSION,
  VERSION,
  getMajorVersion,
  shouldTryToLoadNewUpdate,
  updateAppToLatestVersion,
} from '@services/version'
import { useTranslation } from 'react-i18next'
import ThereWasAProblemUpdating from './ThereWasAProblemUpdating'
import colors from '@constants/colors'

const AppUpdateOnLaunch = ({ children }) => {
  const { t } = useTranslation()
  const [is, setIs] = useState({ checking: false, updating: false, error: false })
  const was = usePrevious(is)

  const updateIs = (newIs) => setIs({ ...is, ...newIs })

  useEffect(() => {
    if (!was?.checking && is.checking) {
      shouldTryToLoadNewUpdate()
        .then((tryUpdate) =>
          updateIs({
            error: false,
            checking: false,
            updating: tryUpdate,
          })
        )
        .catch((error) => {
          console.log('error checking version', error)
          updateIs({ error: true, checkng: false, updating: false })
        })
    }
    if (!was?.updating && is.updating) {
      updateAppToLatestVersion()
        .then(() => updateIs({ updating: false, error: false, checking: false }))
        .catch(() => {
          console.log('error updating version')
          updateIs({ updating: false, error: true, checking: false })
        })
    }
  }, [is, was])

  useEffect(() => {
    if (isApp) {
      CapacitorUpdater.notifyAppReady()
      updateIs({ checking: true })
    }
  }, [])

  if (is.error) return <ThereWasAProblemUpdating tryAgainFcn={() => CapacitorUpdater.reset()} />
  if (is.checking) return <Splash />

  if (is.updating)
    return (
      <View
        center
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: colors.nWhite,
          backgroundImage: `url(/my_hiro_splash.webp)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <View center style={{ width: 66, height: 29, marginTop: 45, marginBottom: 22 }}>
          <style jsx global>{`
            #heart {
              animation: 2.5s ease 0s infinite beat;
            }

            @keyframes beat {
              0%,
              50%,
              100% {
                transform: scale(1, 1);
              }
              30%,
              80% {
                transform: scale(0.87, 0.89);
              }
            }

            @keyframes pulse {
              0%,
              50%,
              100% {
                background: #fee;
              }
              30%,
              80% {
                background: #fff;
              }
            }
          `}</style>
          <Image src="/my_hiro.webp" alt="My Hiro Logo" priority width={927.47 * 0.2} height={674 * 0.2} />
        </View>
        <Spacer height={30} />
      </View>
    )

  const majorRunning = getMajorVersion(VERSION)
  const earliestMajorSupported = getMajorVersion(EARLIEST_SUPPORTED_VERSION)

  if (majorRunning < earliestMajorSupported) {
    return (
      <a href="https://www.myhiro.club" about="_blank">
        <View center style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          <Text weight={600} size={18} center style={{ maxWidth: 240 }}>
            {t('Please use the website www.myhiro.club to order for the next few days.')}
          </Text>
          <Spacer height={30} />
          <Text weight={600} size={14} center style={{ maxWidth: 220 }}>
            {t(
              "We're deploying a new menu and rewards to the app over the next few days which wont also work on the website so we've had to stop people ordering from old app versions."
            )}
          </Text>
        </View>
      </a>
    )
  }

  return children
}

export default AppUpdateOnLaunch
