import { isApp } from '@services/platform'
import AppUpdateOnLaunch from './AppUpdateOnLaunch'
import AppUpdateOnStateChange from './AppUpdateOnStateChange'

import WebUpdateOnLaunch from './WebUpdateOnLaunch'
import WebUpdateOnStateChange from './WebUpdateOnStateChange'

import capacitorConfig from '../../capacitor.config'

const DontUpdateOnLaunch = ({ children }) => children
const DontUpdateOnStateChange = () => null

let UpdateOnLaunch = isApp ? AppUpdateOnLaunch : WebUpdateOnLaunch
let UpdateOnStateChange = isApp ? AppUpdateOnStateChange : WebUpdateOnStateChange

if (capacitorConfig && capacitorConfig?.server) {
  UpdateOnLaunch = isApp ? DontUpdateOnLaunch : WebUpdateOnLaunch
  UpdateOnStateChange = isApp ? DontUpdateOnStateChange : WebUpdateOnStateChange
}

export { UpdateOnLaunch, UpdateOnStateChange }
